<template>
  <div class="info" v-cloak>
    <div class="search">
      <vue-google-autocomplete type="text" id="map" placeholder="請輸入位置" @placechanged="getAddressData" country="tw" onfocus="value = ''"></vue-google-autocomplete>
      <!-- <input type="text" v-model="locate" placeholder="Enter A City" @keyup.enter="getGeoCode()" /> -->
      <button @click="geolocate()" type="button" style="cursor: pointer;"><i class="bi bi-geo-alt-fill"></i></button>
    </div>
    <loading :active.sync="isLoading" loader="dots" background-color="transparent" transition="slide-fade" color="#FFF" :is-full-page="false"></loading>
    <transition name="slide-fade">
        <div v-if="load">
            <div class="location">{{ locationName }}</div>
            <div class="location">{{ Wx }}、{{ CI }}</div>
            <div class="main-info">
                <div data-title="天氣現象" class="WX">
                    <i class="wi" :class="wis_style"></i>
                </div>
            </div>
            <div class="main-info m2">
                <div data-title="溫度資訊" class="TempInfo">
                    <div data-title="平均溫度" class="temp">
                        <div>{{ T }}</div>
                        <div class="info-d">
                            <div class="celsius">
                                <span><i class="wi wi-celsius"></i></span>
                                </div>
                            <div class="t-rang">
                                <span><i class="wi wi-direction-up"></i>{{ MaxT }}</span>
                                <span class="deg">&deg;</span>
                            </div>
                            <div class="t-rang">
                                <span><i class="wi wi-direction-down"></i>{{ MinT }}</span>
                                <span class="deg">&deg;</span>
                            </div>
                        </div>
                    </div>
                    <div data-title="體感溫度" class="AT">體感 {{ AT }}&deg;</div>
                </div>
                <div class="other">
                    <div>
                        <span><i class="bi bi-wind"></i></span>
                        <span>{{BT(WS)}}</span>
                        <!-- <i class="wi" :class="beaufort"></i> -->
                    </div>
                    <div>
                        <span>UV</span>
                        <span>{{uviDes(UVI)}}</span>
                    </div>
                    <div>
                        <span><i class="bi bi-umbrella-fill"></i></span>
                        <span>{{ PoP6h }}%</span>
                    </div>
                    <div>
                        <span><i class="wi wi-humidity"></i></span>
                        <span>{{ RH }}%</span>
                    </div>
                </div>
            </div>
            <div class="main-info m2">
                <div data-title="空氣品質" class="AQI">
                  <div>
                    <div><i class="bi" :class="aqiIcon(Aqi)"></i></div>
                    <div class="AT">空氣 {{ aqiD(Aqi) }}</div>
                  </div>
                </div>
                <div class="other">
                  <div>
                      <span class="title">AQI</span>
                      <span>{{Aqi}}</span>
                  </div>
                  <div>
                      <span class="title">PM2.5</span>
                      <span>{{pm25}}</span>
                  </div>
                  <div>
                      <span class="title">PM10</span>
                      <span>{{pm10}}</span>
                  </div>
                  <div>
                      <span class="title">臭氧</span>
                      <span>{{o3}}</span>
                  </div>
              </div>
            </div>
            <div class="ThreeDayTempList">
                <div v-for="info in 1" :key="info">
                    <div>
                        <div v-for="Wx in WxList" :key="Wx">
                            <span style="display:block; white-space: nowrap; font-size: 10px; letter-spacing: .5px; opacity: .5;">{{ showText(Wx.endTime.slice(0, 10)).split('2022-').join('')}}</span>
                            <span style="font-size: 10px;white-space: nowrap;letter-spacing: .5px; opacity: .5;">{{ Wx.endTime.slice(11, 16)}}</span>
                            <i class="wi" :class="wi(Wx.elementValue[1].value + WxT(Wx.endTime.slice(11, 16)))"></i>
                        </div>
                    </div>
                    <div>
                        <div v-for="temp in TempList" :key="temp" style="opacity: .7">
                            <span style="display:block; white-space: nowrap;">{{ temp.elementValue[0].value }}</span>
                            <span class="deg">&deg;</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
  </div>
</template>
<script>
import VueGoogleAutocomplete from 'vue-google-autocomplete'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import axios from 'axios'
import moment from 'moment'
export default {
  name: 'WeatherBlock2',
  data () {
    return {
      address: '',
      day: moment().format('YYYY年MM月DD日 hh:mm'),
      time: moment().format('ha'),
      locate: '',
      // county: null,
      // district: '',
      // street: null,
      // lat: null,
      // lon: null,
      load: false,
      isLoading: true,
      PoP12: null,
      WeekPoP12: null,
      Wx: null,
      AT: null,
      T: null,
      RH: null,
      CI: null,
      PoP6h: null,
      WS: null,
      WD: null,
      MinT: null,
      UVI: null,
      MaxT: null,
      WxTime: null,
      wiss: null,
      sessions: null,
      wis_style: null,
      cwd_api: 'https://opendata.cwa.gov.tw/api/v1/rest/datastore/',
      cwd_api_week: 'https://opendata.cwa.gov.tw/api/v1/rest/datastore/',
      // googleapis: 'https://maps.googleapis.com/maps/api/geocode/json?languaage=&key=',
      googleapis: 'https://asia-east1-zeta-bebop-269612.cloudfunctions.net/Gmap',
      mapquestapi: 'https://www.mapquestapi.com/geocoding/v1/address?key=', // mapquestapi 反向地理位置 api
      waqi: 'https://api.waqi.info/feed/geo:', // waqi 空氣品質 api
      Aqi: null
    }
  },
  components: {
    VueGoogleAutocomplete,
    Loading
  },
  created () {
    this.geolocate()
  },
  Update () {
    // this.getGeoCode()
    // this.CountyCode()
    this.getCountyInfo()
    this.getCountyInfoWeek()
    this.getCountyAqi()
  },
  methods: {
    // 取得裝置地理位置
    geolocate () {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(position => {
          const coords = position.coords
          // console.log(coords.latitude, coords.longitude)
          // this.getGeoCode(coords.latitude, coords.longitude)
          // console.log(position.coords.latitude); //緯度
          // console.log(position.coords.longitude); //經度
          this.locate = coords.latitude + ',' + coords.longitude
          this.getGeoCode(this.locate)
        },
        error => {
          // this.gettingLocation = false
          console.log(error.message)
        }
        )
      } else {
        console.log(' (T_T) U dont wanna give me your Position')
      }
    },
    // Google 反向地理位置 api
    getGeoCode (locate) {
      // this.lat = lat
      // this.lon = long
      // this.locate = lat + ',' + long
      // this.googleapis_key = 'AIzaSyC78Q5akBDtn-_cH9rAXs2J2ccEFCxZ-co'
      this.mapquestapi_key = '5xb1MXtGa45wVKqWBCKHQdaMv1UbPZI9'
      // this.mapquestapi_key = mapquestapi_key
      // this.mapquestapi = `/mapquestapi/reverse?key=${this.mapquestapi_key}&location=${lat},${long}`
      this.mapquest = this.mapquestapi + `${this.mapquestapi_key}&location=${this.locate}`
      this.google = this.googleapis + `?&address=${this.locate}`
      axios.get(this.google)
        .then((response) => {
          // if (response.data.error_message) {
          //   console.log(response.data.error_message)
          // } else {
          //   console.log(response.data.results[0].locations[0].street)
          // }
          // this.location = response.data.results[0].locations[0]
          // this.LatA = response.data.results[0].providedLocation.latLng.lat
          // this.LngA = response.data.results[0].providedLocation.latLng.lng
          // this.county = this.location.adminArea3
          // this.district = this.location.adminArea5
          // this.street = this.location.street
          this.location = response.data.results[0]
          this.locationName = this.location.formatted_address
          this.a_components = response.data.results[0].address_components
          this.administrative_area = this.a_components.filter(x => x.types[0].includes('administrative_area'))
          this.county = this.administrative_area[2].short_name
          this.district = this.administrative_area[1].short_name
          this.LatA = this.location.geometry.location.lat
          this.LngA = this.location.geometry.location.lng
          this.CountyCode(this.county)
          this.CountyCodeWeek(this.county)
          this.countyInfo = this.CountyCode(this.county)
          this.countyInfoWeek = this.CountyCodeWeek(this.county)
          this.getCountyInfo(this.countyInfo, this.district)
          this.getCountyInfoWeek(this.countyInfoWeek, this.district)
          this.getCountyAqi(this.LatA, this.LngA)
        })
        .catch(error => {
          console.log(error.message)
        })
    },
    // 空氣品質 api
    getCountyAqi (LatA, LngA) {
      this.token = '851b7b600439869a21c540dfb2cfa96fc8f6d8a6' // waqi 空氣品質 api 金鑰
      // this.waqi += `${LatA};${LngA}/?token=${this.token}`
      this.aqi = `${this.waqi}${LatA};${LngA}/?token=${this.token}`
      axios.get(this.aqi)
        .then((response) => {
          this.iaqi = response.data.data.iaqi
          this.Aqi = response.data.data.aqi
          this.pm25 = this.iaqi.pm25.v
          this.pm10 = this.iaqi.pm10.v
          this.o3 = this.iaqi.o3.v
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error)
        })
    },
    // 中央氣象局未來三天天氣預報 api
    getCountyInfo (countyInfo, district) {
      this.isLoading = true
      this.Authorization = 'CWB-8795A583-0454-4DDE-B17C-4AD16A7F9183'
      // this.cwd_api += `${countyInfo}?locationName=${district}&Authorization=${this.Authorization}&format=JSON&elementName=`
      this.cwd = `${this.cwd_api}${countyInfo}?locationName=${district}&Authorization=${this.Authorization}&format=JSON&elementName=`
      axios.get(this.cwd)
        .then((response) => {
          this.info = response.data.records.locations[0].location[0]
          this.PoP12 = this.info.weatherElement[0].time[0].elementValue[0].value // 12小時降雨機率
          this.Wx = this.info.weatherElement[1].time[0].elementValue[0].value // 天氣現象
          this.WxList = this.info.weatherElement[1].time // 天氣現象
          this.AT = this.info.weatherElement[2].time[0].elementValue[0].value // 體感溫度
          this.T = this.info.weatherElement[3].time[0].elementValue[0].value // 溫度
          this.TempList = this.info.weatherElement[3].time // 溫度
          this.RH = this.info.weatherElement[4].time[0].elementValue[0].value // 相對濕度
          this.CI = this.info.weatherElement[5].time[0].elementValue[1].value // 舒適度指數
          // this.WeatherDescription = this.info.weatherElement[6].time[0].elementValue[0].value // 天氣預報綜合描述
          this.PoP6h = this.info.weatherElement[7].time[0].elementValue[0].value // 6小時降雨機率
          this.WS = this.info.weatherElement[8].time[0].elementValue[0].value // 風速
          this.beaufort = this.BT(this.WS)
          this.WD = this.info.weatherElement[9].time[0].elementValue[0].value // 風向
          // this.Td = this.info.weatherElement[10].time[0].elementValue[0].value // 露點溫度
          this.sessions = this.timer(this.time)
          this.wis = this.info.weatherElement[1].time[0].elementValue[1].value
          this.wiss = this.wis + this.sessions
          this.wis_style = this.wi(this.wiss)
          this.WxTime = this.WxList.endTime.slice(11, 16)
          this.WxSessions = this.WxT(this.WxTime)
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error)
        })
      setTimeout(() => {
        this.isLoading = false
      }, 5000)
    },
    // 中相氣象局未來一週天氣預報 api
    getCountyInfoWeek (countyInfoWeek, district) {
      this.isLoading = true
      this.Authorization = 'CWB-8795A583-0454-4DDE-B17C-4AD16A7F9183'
      // this.Authorization = cwd_key
      // this.cwd_api = `/cwd_api/${countyInfo}?locationName=${district}&Authorization=${this.Authorization}&format=JSON&elementName=`
      // this.cwd_api_week += `${countyInfoWeek}?locationName=${district}&Authorization=${this.Authorization}&format=JSON&elementName=`
      this.cwd_week = `${this.cwd_api_week}${countyInfoWeek}?locationName=${district}&Authorization=${this.Authorization}&format=JSON&elementName=`
      axios.get(this.cwd_week)
        .then((response) => {
          this.isLoading = false
          this.load = true
          this.WeekInfo = response.data.records.locations[0].location[0]
          // this.City = response.data.records.locations[0].locationsName
          // this.District = this.WeekInfo.locationName
          this.WeekPoP12 = this.WeekInfo.weatherElement[0].time[0].elementValue[0].value // 12小時降雨機率
          // this.T = this.WeekInfo.weatherElement[1].time[0].elementValue[0].value // 平均溫度
          // this.RH = this.WeekInfo.weatherElement[2].time[0].elementValue[0].value // 平均相對濕度
          // this.MicCI = this.WeekInfo.weatherElement[3].time[0].elementValue[0].value // 最小舒適度指數
          // this.WS = this.WeekInfo.weatherElement[4].time[0].elementValue[0].value // 最大風速
          // this.MaxAT = this.WeekInfo.weatherElement[5].time[0].elementValue[0].value // 最高體感溫度
          // this.Wx = this.WeekInfo.weatherElement[6].time[0].elementValue[0].value // 天氣現象
          // this.MaxCI = this.WeekInfo.weatherElement[7].time[0].elementValue[0].value // 最大舒適度指數
          this.MinT = this.WeekInfo.weatherElement[8].time[0].elementValue[0].value // 最低溫度
          this.UVI = this.WeekInfo.weatherElement[9].time[0].elementValue[0].value // 紫外線指數
          // this.WeatherDescription = this.WeekInfo.weatherElement[10].time[0].elementValue[0].value // 天氣預報綜合描述
          // this.MinAT = this.WeekInfo.weatherElement[11].time[0].elementValue[0].value // 最低體感溫度
          this.MaxT = this.WeekInfo.weatherElement[12].time[0].elementValue[0].value // 最高溫度
          // this.WD = this.WeekInfo.weatherElement[13].time[0].elementValue[0].value // 風向
          // this.Td = this.WeekInfo.weatherElement[14].time[0].elementValue[0].value // 平均露點溫度
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error)
        })
      setTimeout(() => {
        this.isLoading = false
      }, 5000)
    },
    // 天氣說明轉換icon
    wi (value) {
      switch (value) {
        case '01d':
          return 'wi-day-sunny'
        case '01n':
          return 'wi-night-clear'
        case '02d':
          return 'wi-day-sunny-overcast'
        case '02n':
          return 'wi-night-alt-partly-cloudy'
        case '03d':
        case '04d':
          return 'wi-day-cloudy'
        case '03n':
        case '04n':
          return 'wi-night-alt-cloudy'
        case '05d':
        case '05n':
        case '06d':
        case '06n':
          return 'wi-cloudy'
        case '07d':
        case '07n':
          return 'wi-cloud'
        case '08d':
        case '08n':
        case '09d':
        case '09n':
        case '10d':
        case '10n':
        case '11d':
        case '11n':
        case '30d':
        case '30n':
        case '32d':
        case '32n':
        case '38d':
        case '38n':
          return 'wi-showers'
        case '12d':
        case '12n':
        case '1d3':
        case '13n':
        case '14d':
        case '14n':
        case '39d':
        case '39n':
          return 'wi-rain'
        case '15d':
        case '15n':
        case '16d':
        case '16n':
        case '34d':
        case '34n':
        case '36d':
        case '36n':
          return 'wi-storm-showers'
        case '17d':
        case '17n':
        case '18d':
        case '18n':
        case '41d':
        case '41n':
          return 'wi-thunderstorm'
        case '19d':
        case '20d':
        case '29d':
        case '31d':
          return 'wi-day-rain-mix'
        case '19n':
        case '20n':
        case '29n':
        case '31n':
          return 'wi-night-alt-rain-mix'
        case '21d':
        case '22d':
        case '33d':
        case '35d':
          return 'wi-day-storm-showers'
        case '21n':
        case '22n':
        case '33n':
        case '35n':
          return 'wi-night-alt-storm-showers'
        case '23d':
        case '23n':
          return 'wi-sleet'
        case '24d':
        case '25d':
        case '26d':
        case '27d':
          return 'wi-night-fog'
        case '24n':
        case '25n':
        case '26n':
        case '27n':
          return 'wi-night-fog'
        case '28d':
        case '28n':
          return 'wi-fog'
        case '37d':
        case '37n':
        case '40d':
        case '40n':
          return 'wi-sleet'
        case '42d':
        case '42n':
          return 'wi-snowflake-cold'
      }
    },
    // 時間轉換中文時間
    WxTx (value) {
      switch (value) {
        case '18:00':
          return '下午 6 時'
        case '21:00':
          return '晚上 9 時'
        case '00:00':
          return '午夜 12時'
        case '03:00':
          return '凌晨 3 時'
        case '06:00':
          return '上午 6 時'
        case '09:00':
          return '上午 9 時'
        case '12:00':
          return '中午 12時'
        case '15:00':
          return '下午 3 時'
      }
    },
    // 時間轉換日夜
    timer (value) {
      switch (value) {
        case '5' + 'pm':
        case '6' + 'pm':
        case '7' + 'pm':
        case '8' + 'pm':
        case '9' + 'pm':
        case '10' + 'pm':
        case '11' + 'pm':
        case '12' + 'am':
        case '1' + 'am':
        case '2' + 'am':
        case '3' + 'am':
        case '4' + 'am':
          return 'n'
        case '5' + 'am':
        case '6' + 'am':
        case '7' + 'am':
        case '8' + 'am':
        case '9' + 'am':
        case '10' + 'am':
        case '11' + 'am':
        case '12' + 'pm':
        case '1' + 'pm':
        case '2' + 'pm':
        case '3' + 'pm':
        case '4' + 'pm':
          return 'd'
      }
    },
    // 時間轉換日夜
    WxT (value) {
      switch (value) {
        case '18:00':
        case '21:00':
        case '00:00':
        case '03:00':
          return 'n'
        case '06:00':
        case '09:00':
        case '12:00':
        case '15:00':
          return 'd'
      }
    },
    // 風速轉換說明
    BT (value) {
      switch (value) {
        case '0':
          return '無風'
        case '1':
          return '軟風'
        case '2':
          return '輕風'
        case '3':
          return '微風'
        case '4':
          return '和風'
        case '5':
          return '清風'
        case '6':
          return '強風'
        case '7':
          return '疾風'
        case '8':
          return '大風'
        case '9':
          return '烈風'
        case '10':
          return '狂風'
        case '11':
          return '暴風'
        case '12':
          return '颶風'
      }
    },
    // 紫外線轉圜說明
    uviDes (value) {
      if (value > 2) {
        if (value <= 4) {
          return '弱'
        } else if (value <= 6) {
          return '中等'
        } else if (value <= 9) {
          return '強'
        } else if (value <= 15) {
          return '極強'
        }
      } else if (-value < 3) {
        return '微弱'
      }
      return value
    },
    // 一週天氣縣市轉換代碼
    CountyCodeWeek (value) {
      switch (value) {
        case '宜蘭縣':
          return 'F-D0047-003'
        case '桃園市':
          return 'F-D0047-007'
        case '新竹縣':
          return 'F-D0047-011'
        case '苗栗縣':
          return 'F-D0047-015'
        case '彰化縣':
          return 'F-D0047-019'
        case '南投縣':
          return 'F-D0047-023'
        case '雲林縣':
          return 'F-D0047-027'
        case '嘉義縣':
          return 'F-D0047-031'
        case '屏東縣':
          return 'F-D0047-035'
        case '臺東縣':
        case '台東縣':
          return 'F-D0047-039'
        case '花蓮縣':
          return 'F-D0047-043'
        case '澎湖縣':
          return 'F-D0047-047'
        case '基隆市':
          return 'F-D0047-051'
        case '新竹市':
          return 'F-D0047-055'
        case '嘉義市':
          return 'F-D0047-059'
        case '臺北市':
        case '台北市':
          return 'F-D0047-063'
        case '高雄市':
          return 'F-D0047-067'
        case '新北市':
          return 'F-D0047-071'
        case '臺中市':
        case '台中市':
          return 'F-D0047-075'
        case '臺南市':
        case '台南市':
          return 'F-D0047-079'
        case '連江縣':
          return 'F-D0047-083'
        case '金門縣':
          return 'F-D0047-087'
      }
    },
    // 三日天氣縣市轉換代碼
    CountyCode (value) {
      switch (value) {
        case '宜蘭縣':
          return 'F-D0047-001'
        case '桃園市':
          return 'F-D0047-005'
        case '新竹縣':
          return 'F-D0047-009'
        case '苗栗縣':
          return 'F-D0047-013'
        case '彰化縣':
          return 'F-D0047-017'
        case '南投縣':
          return 'F-D0047-021'
        case '雲林縣':
          return 'F-D0047-025'
        case '嘉義縣':
          return 'F-D0047-029'
        case '屏東縣':
          return 'F-D0047-033'
        case '臺東縣':
        case '台東縣':
          return 'F-D0047-037'
        case '花蓮縣':
          return 'F-D0047-041'
        case '澎湖縣':
          return 'F-D0047-045'
        case '基隆市':
          return 'F-D0047-049'
        case '新竹市':
          return 'F-D0047-053'
        case '嘉義市':
          return 'F-D0047-057'
        case '臺北市':
        case '台北市':
          return 'F-D0047-061'
        case '高雄市':
          return 'F-D0047-065'
        case '新北市':
          return 'F-D0047-069'
        case '臺中市':
        case '台中市':
          return 'F-D0047-073'
        case '臺南市':
        case '台南市':
          return 'F-D0047-077'
        case '連江縣':
          return 'F-D0047-081'
        case '金門縣':
          return 'F-D0047-085'
      }
    },
    // 時間換算 明天、後天、大後天
    showText (timeString) {
      // const date = moment(timeString).valueOf()
      // const today = moment(new Date()).valueOf()
      const dayTime = 1000 * 60 * 60 * 24 // 86400000
      // const delta = date - today // date - today 可得到明天後天 ； 換成 today - date  可得到昨天、前天。
      const delta = moment(timeString).diff(moment(new Date()))
      if (delta > 0) {
        if (delta <= dayTime) {
          return '明天'
        } else if (delta <= dayTime * 2) {
          return '後天'
        }// else if (delta <= dayTime * 3) {
        // return '大後天'
        // }
      } else if (-delta < dayTime) {
        return '今天'
      }
      return timeString
    },
    // 空氣品質轉換說明
    aqiD (value) {
      if (value > 50) {
        if (value <= 100) {
          return '良'
        } else if (value <= 150) {
          return '輕度污染'
        } else if (value <= 200) {
          return '中度污染'
        } else if (value <= 300) {
          return '重度污染'
        } else if (value > 301) {
          return '嚴重污染'
        }
      } else if (-value < 50) {
        return '優'
      }
      return value
    },
    // 空氣品質轉換icon
    aqiIcon (value) {
      if (value > 50) {
        if (value <= 100) {
          return 'bi-emoji-smile yellow'
        } else if (value <= 150) {
          return 'bi-emoji-neutral orange'
        } else if (value <= 200) {
          return 'bi-emoji-frown red'
        } else if (value <= 300) {
          return 'bi-emoji-dizzy purple'
        } else if (value > 301) {
          return 'bi-emoji-dizzy dark-pink'
        }
      } else if (-value < 50) {
        return 'bi-emoji-smile green'
      }
      return value
    },
    /**
     * When the location found
     * @param {Object} addressData Data of the found location
     * @param {Object} placeResultData PlaceResult object
     * @param {String} id Input container ID
     */
    // Google 自動輸入地址
    getAddressData (addressData, placeResultData, id) {
      console.log(this.area = placeResultData.address_components.filter(x => x.types[0].includes('administrative_area')))
      // console.log(addressData)
      // console.log(placeResultData)
      this.locationName = placeResultData.formatted_address
      this.county = this.area[1].short_name
      this.district = this.area[0].short_name
      // this.county = addressData.administrative_area_level_1
      // this.district = addressData.administrative_area_level_3
      // this.LatA = placeResultData.geometry.viewport.Ab.h
      // this.LngA = placeResultData.geometry.viewport.Va.h
      this.LatA = addressData.latitude
      this.LngA = addressData.longitude
      this.CountyCode(this.county)
      this.CountyCodeWeek(this.county)
      this.countyInfo = this.CountyCode(this.county)
      this.countyInfoWeek = this.CountyCodeWeek(this.county)
      this.getCountyInfo(this.countyInfo, this.district)
      this.getCountyInfoWeek(this.countyInfoWeek, this.district)
      this.getCountyAqi(this.LatA, this.LngA)
    }
  }
}
</script>
<style>
.info {
  position: relative;
  min-height: 260px;
}
.ab {
  position: absolute;
  width:calc(100% - 60px);
  height:calc(100% - 60px);
  top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.slide-fade-enter-active {
  transition: all 0.25s ease;
}
.slide-fade-leave-active {
  transition: all 0.25s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(30px);
  opacity: 0;
  height:200px;
}
.slide-fade-leave-to {
  transform: translateY(-30px);
  opacity: 0;
  height: auto;
}

.ThreeDayTempList {
  overflow: hidden;
  overflow-x: scroll;
  padding: 10px;
  border: 1px solid #fff;
  border-radius: 5px;
  margin-top: 20px;
  background: #00000036;
}
.ThreeDayTempList div div {
  display: flex;
  justify-content: left;
  align-items: center;
  margin-right:33px;
  width: 25px;
}
.ThreeDayTempList div div div{
    /* padding-right:20px; */
    display: block;
    position: relative;
}
.ThreeDayTempList div div div span {
    width: 25px;
    text-align: center;
}
.ThreeDayTempList div div div span.deg {
    position: absolute;
    top:0;
}
</style>
