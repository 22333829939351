<template>
<div id="app">
  <HeaderBlock title="Weather Wacther" />
  <WeatherBlock/>
</div>
</template>

<script>
import HeaderBlock from './components/HeaderBlock.vue'
import WeatherBlock from './components/WeatherBlock2.vue'

export default {
  name: 'App',
  components: {
    HeaderBlock,
    WeatherBlock
  }
}
</script>
<style lang="scss">
@import "@/assets/scss/main.scss";
</style>
