<template>
<header class="title">
  {{ title }}
</header>
</template>

<script>
export default {
  name: 'HeaderBlock',
  props: {
    title: String
  }
}
</script>
